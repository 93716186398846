<template>
  <div class="price-container">
    <div class="nav-title">
      <van-icon name="arrow-left" class="icon" size="24" @click.prevent.stop="$router.back(-1)"/>
      <div id="title">{{$route.query.title}}</div>
    </div>
    <img class="image_box" :src="imgUrl" alt="" @click="preImages"/>
  </div>

</template>

<script>
  import { getHsPrice } from 'api/api'
  import wx from 'weixin-js-sdk'

  export default {
    name: 'Price',
    data () {
      return {
        imgUrl: ''
      }
    },
    methods: {
      funcReadImgInfo (id) {
        getHsPrice(id).then(res => {
          this.imgUrl = res.image_url
        })
      },
      preImages () {
        wx.previewImage({
          urls: [this.imgUrl]
        })
      }
    },
    created () {
      this.funcReadImgInfo(this.$route.query.index)
    }
  }
</script>

<style lang="less" scoped>
  .nav-title {
    display: flex;
    align-items: center;
    height: 0.88rem;
    border-bottom: 1px solid #ccc;

    .icon {
      flex: 0 0 .4rem;
      padding: 0 .2rem;
    }

    #title {
      flex: 1;
      text-align: center;
      margin-left: -0.8rem;
    }
  }

  .image_box {
    width: 100%;
  }
</style>
